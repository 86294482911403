.VideoPlayer {
  border-radius: 10px;
  overflow: hidden;
}

.videoContainer {
  position: relative;

}

.videoWrapper {
  position: relative;
}

.watermark {
  position: absolute;
  bottom: 20px; 
  right: 20px; 
  width: 100px; 
  height: auto;
  opacity: 0.5; 
  z-index: 10;
}


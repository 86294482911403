.crumb {

  &.parent {
    font-size: 1.2rem;
    color: $gray-600;
    margin-right: 0.5rem;
    cursor: pointer;
    display: inline-block;

    @media (hover: hover) and (pointer: fine) {
      transition: all 0.2s;
    }

    &:hover {
      color: $gray-800;
    }

    &:after {
      margin-left: 0.5rem;
      content: '»';
    }
  }

  &.child {
    color: black;
  }
}
.SortContainer {
  background: #f4f5f9;
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e8f0;
  border-radius: 5px;
  width: 100%;
}

.SortContainerMinimal {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.SortHeading {
  color: #474d66;
  text-transform: uppercase;
}
.SortHeadingMinimal {
  color: #474d66;
  font-weight: bold;
  margin-bottom:0.2rem;
}

/* @import '~bootstrap/scss/bootstrap.scss'; */
@import 'react-rater/lib/react-rater.css';

@import './styles/colors';
@import './styles/grid';
@import './styles/breadcrumbs';
@import './styles/rater';


html,
body {
  margin: 0;
  height: 100%;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

html {
  font-size: 18px;
  line-height: 1.4rem;
}

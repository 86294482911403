

.CommentBox,
.ReplyBox {
    min-width: 250px;
}

.ReplyBox {
    margin-left: 3rem;
}

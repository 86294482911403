.VideoCard {
  background: trasparent;
  width: 100%;
  /* min-width: 250px; */

  &Image {
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    max-height: 10rem;
  }
  &Content {
    padding: 1rem;
    border-radius: 0;
  }
  &Footer {
    padding: 1rem;
    border-radius: 0 0 10px 10px;
  }
}

/* Hide sidenav on screens < medium  */
@media (max-width: 768px) {
  .SideNavContainer {
    display: none;
  }
}
/* Show sidenav on screens > medium */
@media (min-width: 768px) {
  .SideNavContainer {
    display: flex;
  }
}

.SideNav {
  &Tab {
    &[class~='active'] {
      font-weight: bold;
    }
    
    &:visited {
      color: black; // Prevent color change after click
    }
  }
}

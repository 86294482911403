.react-rater-star {
  padding: 0.2rem;
  font-size: 1rem;
}

.react-rater-star {
  &.is-active {
    color: $orange-500;
  }
  &.will-be-active,
  &:hover {
    color: $orange-100;
  }
}
